import { connect } from 'react-redux';

import MainMenu from './MainMenu';

const mapStateToProps = state => ({
  temp: state.temp,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
