import {
  AC_LOGOUT,
  AC_SET_AUTH_DATA,
  AC_SET_AUTHORIZATION_IS_CHECKED,
} from '../action-types';
import { acRequestError, acRequestStart, acRequestSuccess } from './networking';
import { ENTITY_ID, MESSAGES_TYPE } from '../../../constants';
import { getNetworkError } from '../../../utils/network-requests';
import { acResetApp } from './common';
import { getSessionToken } from '../../selectors';
import { acAddMessage } from './messages';
import toErrorMessageObject from '../../../utils/to-error-message-object';

export const acSetAuthData = (login, sessionToken) => ({
  type: AC_SET_AUTH_DATA,
  payload: { login, sessionToken },
});

export const acLogout = () => ({
  type: AC_LOGOUT,
});

export const acSetAuthorizationIsChecked = isChecked => ({
  type: AC_SET_AUTHORIZATION_IS_CHECKED,
  payload: isChecked,
});

export const acGetProfile = callback => async (dispatch, getState, api) => {
  try {
    dispatch(acRequestStart(ENTITY_ID.AUTH));

    const sessionToken = getSessionToken(getState());
    const data = await api.auth.getProfile(sessionToken);

    callback(data);

    dispatch(acRequestSuccess(ENTITY_ID.AUTH));
  } catch (err) {
    const networkError = getNetworkError(err);
    dispatch(acRequestError(ENTITY_ID.AUTH, networkError));

    if (err?.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
      dispatch(
        acAddMessage(
          toErrorMessageObject({
            message: 'Unauthorized request',
          }),
        ),
      );
    } else {
      dispatch(acAddMessage({ ...networkError, type: MESSAGES_TYPE.ERROR }));
    }
  }
};

export const acSignIn = (fields, mode, callback) => async (
  dispatch,
  getState,
  api,
) => {
  try {
    dispatch(acRequestStart(ENTITY_ID.AUTH));

    if (mode === 'UPDATE') {
      const sessionToken = getSessionToken(getState());
      const data = await api.auth.update({ mode, sessionToken, ...fields });
      if (typeof data === 'string') {
        dispatch(acSetAuthData(fields.login, sessionToken));
        dispatch(
          acAddMessage({
            message: 'Updated successful',
            status: '',
            date: new Date().toLocaleString(),
            type: MESSAGES_TYPE.SUCCESS,
          }),
        );
        callback();
      }
      callback(data);
      dispatch(acRequestSuccess(ENTITY_ID.AUTH));
    } else {
      const data = await api.auth.signIn({ mode, ...fields });

      if (typeof data === 'string') {
        dispatch(acSetAuthData(fields.login, data));
        dispatch(
          acAddMessage({
            message: 'Authorization successful',
            status: '',
            date: new Date().toLocaleString(),
            type: MESSAGES_TYPE.SUCCESS,
          }),
        );
        callback();
      }

      callback(data);
      dispatch(acRequestSuccess(ENTITY_ID.AUTH));
    }
  } catch (e) {
    const networkError = getNetworkError(e);
    dispatch(acRequestError(ENTITY_ID.AUTH, networkError));
    dispatch(acAddMessage({ ...networkError, type: MESSAGES_TYPE.ERROR }));

    if (networkError.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
    }
  }
};

export const acChangePassword = (payload, callback) => async (
  dispatch,
  getState,
  api,
) => {
  try {
    dispatch(acRequestStart(ENTITY_ID.AUTH));

    const sessionToken = getSessionToken(getState());
    const data = await api.auth.changePassword({ sessionToken, ...payload });
    if (typeof data === 'string') {
      dispatch(
        acAddMessage({
          message: 'Password has been changed',
          status: '',
          date: new Date().toLocaleString(),
          type: MESSAGES_TYPE.SUCCESS,
        }),
      );
      callback();
    } else {
      callback(data);
    }

    dispatch(acRequestSuccess(ENTITY_ID.AUTH));
  } catch (err) {
    const networkError = getNetworkError(err);
    dispatch(acRequestError(ENTITY_ID.AUTH, networkError));
    dispatch(acAddMessage({ ...networkError, type: MESSAGES_TYPE.ERROR }));

    if (networkError.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
    }
  }
};

export const acSignOff = callback => async (dispatch, getState, api) => {
  try {
    dispatch(acRequestStart(ENTITY_ID.AUTH));

    const sessionToken = getSessionToken(getState());
    const result = await api.auth.signOff(sessionToken);

    if (result === true) {
      dispatch(acLogout());
      dispatch(acResetApp());
      callback();
    }

    dispatch(acRequestSuccess(ENTITY_ID.AUTH));
    dispatch(
      acAddMessage({
        message: 'Signed off',
        status: '',
        date: new Date().toLocaleString(),
        type: MESSAGES_TYPE.INFO,
      }),
    );
  } catch (e) {
    const networkError = getNetworkError(e);
    dispatch(acRequestError(ENTITY_ID.AUTH, networkError));
    dispatch(acAddMessage({ ...networkError, type: MESSAGES_TYPE.ERROR }));
    dispatch(acLogout());
  }
};

export const acPing = () => async (dispatch, getState, api) => {
  const sessionToken = getSessionToken(getState());

  if (sessionToken) {
    try {
      dispatch(acRequestStart(ENTITY_ID.API));

      await api.auth.ping(sessionToken);

      dispatch(acSetAuthorizationIsChecked(true));
      dispatch(acRequestSuccess(ENTITY_ID.API));
    } catch (e) {
      dispatch(acRequestError(ENTITY_ID.API, getNetworkError(e)));
      if (e?.status === 401) {
        dispatch(acLogout());
        dispatch(acResetApp());
      }
    }
  } else {
    dispatch({ type: 'AC_EMPTY' });
  }
};
