import { isFilledString } from './common-utils';

const getUrlParams = () => {
  const { search } = window.location;

  const params = {};
  const str = search.includes('?') ? search.split('?')[1] : '';
  if (isFilledString(str)) {
    str.split('&').forEach(param => {
      const i = param.indexOf('=');
      const key = i > 0 ? param.substring(0, i) : '';
      const value =
        i < param.length - 1 ? param.substring(i + 1, param.length) : '';
      if (key && value) {
        params[key] = value;
      }
    });
  }

  return params;
};

export default getUrlParams;
