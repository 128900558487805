import { connect } from 'react-redux';

import AuthForm from './AuthForm';
import { getIsUpdateMode, getLogin } from '../../../../../store/selectors';
import {
  acAddModal,
  acCloseAllModals,
  acGetProfile,
  acRemoveModal,
  acSignIn,
} from '../../../../../store/actions';
import { MODALS_ID } from '../../../../../constants';

const cancel = () => acRemoveModal(MODALS_ID.AUTH_MODAL);

const mapStateToProps = state => ({
  currentLogin: getLogin(state),
  isUpdateMode: getIsUpdateMode(state),
});

const mapDispatchToProps = {
  closeAllModals: acCloseAllModals,
  onCancel: cancel,
  onSubmit: acSignIn,
  getUserProfile: acGetProfile,
  changePassword: () => acAddModal(MODALS_ID.CHANGE_PASSWORD_MODAL),
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);
