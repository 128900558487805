import { connect } from 'react-redux';

import SnackService from './SnackService';
import { getMessages } from '../../store/selectors';
import { acRemoveMessage } from '../../store/actions';

const mapStateToProps = state => ({
  snacks: getMessages(state),
});

const mapDispatchToProps = {
  removeSnack: acRemoveMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackService);
