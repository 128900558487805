import { get } from 'lodash';
import { createSelector } from 'reselect';

import { isFilledString } from '../../utils/common-utils';
import { MODELS } from '../../constants';

export const getLogin = state => get(state, MODELS.LOGIN, '');

export const getSessionToken = state => get(state, MODELS.SESSION_TOKEN, '');

export const getAuthorizationIsChecked = state =>
  get(state, MODELS.AUTHORIZATION_IS_CHECKED);

export const getIsLogged = createSelector(
  [getSessionToken, getAuthorizationIsChecked],
  (sessionToken, isChecked) => {
    return isFilledString(sessionToken) && isChecked;
  },
);
