import { connect } from 'react-redux';

import Captcha from './Captcha';
import { acCreateCaptcha, acUpdateCaptcha } from '../../store/actions';

const mapDispatchToProps = {
  createCaptcha: acCreateCaptcha,
  updateCaptcha: acUpdateCaptcha,
};

export default connect(null, mapDispatchToProps)(Captcha);
