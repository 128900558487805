import React from 'react';

import Modal from '../../Modal';
import { MODALS_ID } from '../../../constants';

import AuthForm from './shared/AuthForm';

import styles from './AuthModal.module.scss';

const AuthModal = () => {
  const id = MODALS_ID.AUTH_MODAL;

  return (
    <Modal
      className={styles['auth-modal']}
      closeOnClick
      closeOnEscape
      id={id}
      isCentered
    >
      <AuthForm />
    </Modal>
  );
};

export default AuthModal;
