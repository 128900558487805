import { connect } from 'react-redux';

import Page from './Page';
import { acSetLocation } from '../../store/actions';

const mapDispatchToProps = {
  acSetLocation,
};

export default connect(null, mapDispatchToProps)(Page);
