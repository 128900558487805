import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './List.module.scss';

const List = ({ children, className, childRef, ...rest }) => {
  const ownClassName = classNames(styles['list'], className && className);
  const ref = useRef(null);

  return (
    <ul className={ownClassName} ref={childRef || ref} {...rest}>
      {children}
    </ul>
  );
};

List.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  childRef: PropTypes.object,
  className: PropTypes.string,
};

List.defaultProps = {
  children: [],
  childRef: null,
  className: '',
};

export default List;
