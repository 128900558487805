import { connect } from 'react-redux';

import Preloader from './Preloader';
import { getIsFetching } from '../../../store/selectors/networking-selectors';

const mapStateToProps = state => ({
  isOpen: getIsFetching(state),
});

export default connect(mapStateToProps)(Preloader);
