import React, { useRef } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';

import SnackItem from './shared';
import { isFilledArray } from '../../utils/common-utils';

import styles from './SnackService.module.scss';

const SnackService = ({ snacks, removeSnack }) => {
  const root = useRef(document.getElementById('modal-root'));

  const items = isFilledArray(snacks)
    ? snacks
        .map(({ message, status, date, type, id }) => (
          <SnackItem
            key={id}
            id={id}
            message={message}
            status={status}
            date={date}
            type={type}
            onClose={removeSnack}
          />
        ))
        .slice(-3)
    : [];

  return ReactDom.createPortal(
    <div className={styles['snack-list-wrapper']}>
      <ul className={styles['snack-list']}>{items}</ul>
    </div>,
    root.current,
  );
};

SnackService.propTypes = {
  snacks: PropTypes.arrayOf(PropTypes.shape({})),
  removeSnack: PropTypes.func.isRequired,
};

SnackService.defaultProps = {
  snacks: [],
};

export default SnackService;
