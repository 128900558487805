import getUrlAuthData from './get-url-auth-data';
import {
  getAuthDataFromStorage,
  setAuthDataToStorage,
} from './auth-data-in-storage';

const getManagedAuthData = () => {
  let { login, sessionToken } = getUrlAuthData();
  if (login) {
    setAuthDataToStorage(login, sessionToken);
  } else {
    ({ login, sessionToken } = getAuthDataFromStorage());
  }

  return { login, sessionToken };
};

export default getManagedAuthData;
