import { connect } from 'react-redux';

import AccountMenuForm from './AccountMenuForm';
import { getIsLogged } from '../../../../../store/selectors';
import {
  acAddModal,
  acCloseAllModals,
  acSignOff,
} from '../../../../../store/actions';
import { MODALS_ID } from '../../../../../constants';

const mapStateToProps = state => ({
  isLogged: getIsLogged(state),
});

const mapDispatchToProps = {
  onLogin: () => acAddModal(MODALS_ID.AUTH_MODAL),
  onLogout: acSignOff,
  closeAllModals: acCloseAllModals,
  openProfile: () => acAddModal(MODALS_ID.AUTH_MODAL, { isUpdateMode: true }),
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenuForm);
