import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MainMenuIcon = ({ className }) => (
  <svg
    className={classNames('icon', { className })}
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    preserveAspectRatio="xMidYMid meet"
    fill="transparent"
    stroke="currentcolor"
    strokeWidth="2"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 4 H31 M1 12 H31 M1 20 H31 M1 28 H31" />
  </svg>
);

MainMenuIcon.propTypes = {
  className: PropTypes.string,
};

MainMenuIcon.defaultProps = {
  className: '',
};

export default MainMenuIcon;
