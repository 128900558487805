import React from 'react';

import Modal from '../../Modal';
import MainMenuForm from './shared/MainMenuForm';
import { MODALS_ID } from '../../../constants';

import styles from './MainMenu.module.scss';

const MainMenu = () => {
  return (
    <Modal
      className={styles['main-menu']}
      closeOnClick
      closeOnEscape
      id={MODALS_ID.MAIN_MENU}
      isCentered
    >
      <MainMenuForm />
    </Modal>
  );
};

export default MainMenu;
