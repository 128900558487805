import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CrossIcon = ({ className }) => (
  <svg
    className={classNames('icon', { className })}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    preserveAspectRatio="xMidYMid meet"
    fill="transparent"
    stroke="currentcolor"
    strokeWidth="4"
    strokeLinecap="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 2 L30 30 M2 30 L30 2" />
  </svg>
);

CrossIcon.propTypes = {
  className: PropTypes.string,
};

CrossIcon.defaultProps = {
  className: '',
};

export default CrossIcon;
