const goToPageWithAuthParams = host => e => {
  e.preventDefault();

  let url = host;
  const state = {
    l: localStorage.getItem('login'),
    st: localStorage.getItem('sessionToken'),
  };
  if (state.l) {
    const stateParam = btoa(JSON.stringify(state));
    url = `${url}?state=${stateParam}`;
  }

  window.location = url;
};

export default goToPageWithAuthParams;
