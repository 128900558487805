import { connect } from 'react-redux';

import AuthInfoService from './AuthInfoService';
import { acPing } from '../../store/actions';

const mapDispatchToProps = {
  ping: acPing,
};

export default connect(null, mapDispatchToProps)(AuthInfoService);
