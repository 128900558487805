import { AC_SET_MEDIA, AC_SET_TOUCH_SCREEN_DETECTED } from '../action-types';

export const acSetMedia = valueObj => ({
  type: AC_SET_MEDIA,
  payload: valueObj,
});

export const acSetTouchScreenDetected = touchScreenDetected => ({
  type: AC_SET_TOUCH_SCREEN_DETECTED,
  payload: { touchScreenDetected },
});
