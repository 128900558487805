import React from 'react';

import Modal from '../../Modal';
import ChangePasswordForm from './shared/ChangePasswordForm';
import { MODALS_ID } from '../../../constants';

import styles from './ChangePasswordModal.module.scss';

const ChangePasswordModal = () => {
  return (
    <Modal
      id={MODALS_ID.CHANGE_PASSWORD_MODAL}
      className={styles['change-password-modal']}
      closeOnClick
      closeOnEscape
    >
      <ChangePasswordForm />
    </Modal>
  );
};

export default ChangePasswordModal;
