import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './MenuItemButton.module.scss';

const MenuItemButton = ({ className, children, onClick }) => {
  const ownClassName = classNames(
    styles['menu-item-button'],
    className && className,
  );

  return (
    <button type="button" className={ownClassName} onClick={onClick}>
      {children}
    </button>
  );
};

MenuItemButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

MenuItemButton.defaultProps = {
  children: [],
  className: '',
};

export default MenuItemButton;
