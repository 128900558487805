import { connect } from 'react-redux';

import ChangePasswordForm from './ChangePasswordForm';
import {
  acChangePassword,
  acRemoveModal,
  acCloseAllModals,
} from '../../../../../store/actions';
import { MODALS_ID } from '../../../../../constants';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  close: () => acRemoveModal(MODALS_ID.CHANGE_PASSWORD_MODAL),
  closeAll: acCloseAllModals,
  onSubmit: acChangePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
