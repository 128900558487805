import {
  AC_SET_MEDIA,
  AC_SET_TOUCH_SCREEN_DETECTED,
} from '../actions/action-types';

const initialState = {
  touchScreenDetected: false,
};

const mediaReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case AC_SET_MEDIA:
      return {
        ...state,
        ...action.payload,
      };

    case AC_SET_TOUCH_SCREEN_DETECTED:
      return {
        ...state,
        touchScreenDetected: payload.touchScreenDetected,
      };

    default:
      return state;
  }
};

export default mediaReducer;
