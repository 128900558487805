import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const AccountMenuIcon = ({ className }) => (
  <svg
    className={classNames('icon', { className })}
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    preserveAspectRatio="xMidYMid meet"
    fill="transparent"
    stroke="currentcolor"
    strokeWidth="2"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="8" r="7" />
    <path d="M1 28 A15 10 0 0 1 31 28 M1 28 A15 3 0 0 0 31 28" />
  </svg>
);

AccountMenuIcon.propTypes = {
  className: PropTypes.string,
};

AccountMenuIcon.defaultProps = {
  className: '',
};

export default AccountMenuIcon;
