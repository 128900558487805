import {
  AC_LOGOUT,
  AC_RESET_APP,
  AC_SET_AUTH_DATA,
  AC_SET_AUTHORIZATION_IS_CHECKED,
} from '../actions/action-types';
import { setAuthDataToStorage } from '../../utils/auth-data-in-storage';
import getManagedAuthData from '../../utils/get-managed-auth-data';
import clearLocationSearch from '../../utils/clear-location-search';

const getInitialState = () => ({
  ...getManagedAuthData(),
  authorizationIsChecked: false,
});

const authReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case AC_SET_AUTH_DATA: {
      const {
        payload: { login, sessionToken },
      } = action;

      setAuthDataToStorage(login, sessionToken);

      return {
        ...state,
        login,
        sessionToken,
        authorizationIsChecked: true,
      };
    }

    case AC_LOGOUT:
      setAuthDataToStorage(state.login, '');
      clearLocationSearch();

      return {
        ...state,
        sessionToken: '',
        authorizationIsChecked: false,
      };

    case AC_SET_AUTHORIZATION_IS_CHECKED:
      return {
        ...state,
        authorizationIsChecked: action.payload,
      };

    case AC_RESET_APP:
      return {
        ...getInitialState(),
      };

    default:
      return state;
  }
};

export default authReducer;
