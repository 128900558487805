import { MESSAGES_TYPE } from '../constants';

const toErrorMessageObject = obj => {
  const message = obj?.message || 'Unknown error';
  const date = obj?.date || new Date().toLocaleString();
  const status = obj?.status || '';
  const type = obj?.type || MESSAGES_TYPE.ERROR;

  return {
    message,
    date,
    status,
    type,
  };
};

export default toErrorMessageObject;
