export const isFilledString = v => typeof v === 'string' && v.length > 0;

export const isFilledArray = v => Array.isArray(v) && v.length > 0;

export const getLast = (arr, defaultValue = null) =>
  isFilledArray(arr) ? arr[arr.length - 1] : defaultValue;

/**
 * Checker - checks if key is in item and item[key] value equals to filter[key]
 * @typedef {function} Checker
 * @param {string} key
 * @param {Object} item
 * @param {Object} filter
 * @returns {boolean} The result of checking
 */

/**
 * findIndex
 * @param {Array} arr
 * @param {Object} filter
 * @param {Checker|undefined} checker
 * @returns {number} Index of item in the array or -1
 */
export const findIndex = (arr, filter, checker) => {
  if (!(isFilledArray(arr) && filter)) {
    return -1;
  }

  const keys = Object.keys(filter);

  for (let i = 0; i < arr.length; i++) {
    let test = true;

    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      if (checker) {
        test = checker(key, arr[i], filter);
      } else {
        test = key in arr[i] && filter[key] === arr[i][key];
      }
      if (test === false) {
        break;
      }
    }

    if (test === true) {
      return i;
    }
  }

  return -1;
};

export const findItem = (arr, filter, checker) => {
  const i = findIndex(arr, filter, checker);

  return i > -1 ? arr[i] : null;
};

export const randomFromRange = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
