import { acAddMessage } from './messages';
import toErrorMessageObject from '../../../utils/to-error-message-object';

const createAcCaptcha = apiMethodName => (payload, callback) => async (
  dispatch,
  _,
  api,
) => {
  try {
    const result = await api.captcha[apiMethodName](payload);
    callback(result);
  } catch (err) {
    callback();
    dispatch(acAddMessage(toErrorMessageObject(err)));
  }
};

/**
 * @param {number} payload.x X coordinate of upper left corner of checkbox
 * @param {number} payload.y Y coordinate of upper left corner of checkbox
 * @param {number} payload.width Width of checkbox
 * @param {number} payload.height Height of checkbox
 * @returns {function}
 * Parameter of callback should be an object { code } || { isValid: false, ... }
 * || undefined in a case of network error
 */
export const acCreateCaptcha = createAcCaptcha('createCaptcha');

/**
 * @param {number} payload.x X coordinate of click
 * @param {number} payload.y Y coordinate of click
 * @param {number} payload.code UUID of captcha
 * @returns {function}
 * Parameter of callback should be an object { isCompleted }
 * || undefined in a case of network error
 */
export const acUpdateCaptcha = createAcCaptcha('updateCaptcha');
