import React from 'react';
import PropTypes from 'prop-types';

const PreloaderIcon = ({ className }) => (
  <svg
    className={className}
    width="3rem"
    height="3rem"
    viewBox="0 0 48 48"
    preserveAspectRatio="xMidYMid meet"
    fill="transparent"
    stroke="currentcolor"
    strokeWidth="4"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 22 A22 22 0 1 1 23 46" />
  </svg>
);

PreloaderIcon.propTypes = {
  className: PropTypes.string,
};

PreloaderIcon.defaultProps = {
  className: '',
};

export default PreloaderIcon;
