import { AC_ADD_MESSAGE, AC_REMOVE_MESSAGE } from '../action-types';

export const acAddMessage = payload => ({
  type: AC_ADD_MESSAGE,
  payload,
});

export const acRemoveMessage = messageId => ({
  type: AC_REMOVE_MESSAGE,
  payload: messageId,
});
