import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './FormInput.module.scss';

const FormInput = ({
  className,
  disabled,
  errorMessage,
  id,
  label,
  name,
  type,
  value,
  onChange,
  onEnterPress,
  ...rest
}) => {
  const ownClassName = classNames(styles['form-input'], className, {
    [styles['form-input--disabled']]: disabled,
  });

  const handleKeyPress = e => {
    if (e.charCode === 13) {
      onEnterPress();
    }
  };

  return (
    <div className={ownClassName}>
      <label htmlFor={id} className={styles['form-input__label']}>
        {label}
      </label>
      <input
        className={styles['form-input__input']}
        id={id}
        name={name}
        type={type}
        value={value}
        {...rest}
        onChange={onChange}
        onKeyPress={handleKeyPress}
      />
      {errorMessage && (
        <span className={styles['form-input__error-message']}>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

FormInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func,
};

FormInput.defaultProps = {
  className: '',
  disabled: false,
  errorMessage: null,
  label: '',
  type: 'text',
  value: '',
  onEnterPress: () => {},
};

export default FormInput;
