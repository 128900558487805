import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './FormFieldset.module.scss';

const FormFieldset = ({ children, className, ...rest }) => {
  const ownClassName = classNames(
    styles['form-fieldset'],
    className && className,
  );

  return (
    <fieldset className={ownClassName} {...rest}>
      {children}
    </fieldset>
  );
};

FormFieldset.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
};

FormFieldset.defaultProps = {
  className: '',
};

export default FormFieldset;
