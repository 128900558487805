import MODALS_ID from './modals-id';

const { AUTH_MODAL, MAIN_MENU } = MODALS_ID;

const MODALS = {
  [AUTH_MODAL]: {
    title: 'Authentication modal',
  },
  [MAIN_MENU]: {
    title: 'Main menu',
  },
};

export default MODALS;
