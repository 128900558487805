import { MEDIA_BREAKPOINTS } from '../constants';

const {
  MOBILE: isMobile,
  TABLET: isTablet,
  DESKTOP: isDesktop,
} = MEDIA_BREAKPOINTS;

const getMediaValue = items => {
  let mediaValue = items.reduce(
    (acc, curr) => ({ ...acc, [curr.media]: curr.matches }),
    {},
  );

  mediaValue = {
    ...mediaValue,
    isMobile: mediaValue[isMobile],
    isTablet: mediaValue[isTablet],
    isDesktop: mediaValue[isDesktop],
  };

  return mediaValue;
};

export default getMediaValue;
