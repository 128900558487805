import React from 'react';

import Modal from '../../Modal';
import AccountMenuForm from './shared/AccountMenuForm';
import { MODALS_ID } from '../../../constants';

import styles from './AccountModalMenu.module.scss';

const AccountModalMenu = () => {
  const id = MODALS_ID.ACCOUNT_MODAL_MENU;

  return (
    <Modal
      className={styles['account-modal-menu']}
      closeOnClick
      closeOnEscape
      id={id}
      isCentered
    >
      <AccountMenuForm />
    </Modal>
  );
};

export default AccountModalMenu;
