const roundedRect = (context, cx, cy, width, height, radius, isFilled) => {
  const x = cx - width / 2;
  const y = cy - height / 2;

  context.beginPath();
  context.moveTo(x, y + radius);
  context.lineTo(x, y + height - radius);
  context.arcTo(x, y + height, x + radius, y + height, radius);
  context.lineTo(x + width - radius, y + height);
  context.arcTo(x + width, y + height, x + width, y + height - radius, radius);
  context.lineTo(x + width, y + radius);
  context.arcTo(x + width, y, x + width - radius, y, radius);
  context.lineTo(x + radius, y);
  context.arcTo(x, y, x, y + radius, radius);
  context.closePath();
  context.stroke();
  if (isFilled) {
    context.fill();
  }
};

export default roundedRect;
