import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ListItem.module.scss';

const ListItem = ({ className, children, ...rest }) => {
  const ownClassName = classNames(styles['list-item'], className && className);

  return (
    <li className={ownClassName} {...rest}>
      {children}
    </li>
  );
};

ListItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

ListItem.defaultProps = {
  children: [],
  className: '',
};

export default ListItem;
