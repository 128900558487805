import { parseResponse, post, put, get } from '../utils/network-requests';
import { MODES, URLS } from '../constants';

const AUTH_URLS = {
  [MODES.LOGIN]: URLS.LOGIN,
  [MODES.REGISTRATION]: URLS.REGISTER,
  [MODES.UPDATE]: URLS.USER_UPDATE,
};

const signIn = async ({ mode, ...rest }) => {
  const url = AUTH_URLS[mode];

  const response = await post({ url, body: { ...rest } });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return response.status === 201
    ? response.headers.get('session-token')
    : parsedResponse;
};

const signOff = async sessionToken => {
  const response = await put({
    url: URLS.LOGOUT,
    headers: { 'session-token': sessionToken },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return true;
};

const update = async ({ mode, sessionToken, ...rest }) => {
  const url = AUTH_URLS[mode];

  const response = await put({
    url,
    headers: { 'session-token': sessionToken },
    body: { ...rest },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const changePassword = async ({ sessionToken, ...rest }) => {
  const url = URLS.CHANGE_PASSWORD;

  const response = await put({
    url,
    headers: { 'session-token': sessionToken },
    body: { ...rest },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const getProfile = async sessionToken => {
  const url = URLS.USER_PROFILE;

  const response = await get({
    url,
    headers: { 'session-token': sessionToken },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const ping = async sessionToken => {
  const response = await get({
    url: URLS.PING,
    headers: { 'session-token': sessionToken },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return true;
};

const authApi = {
  signIn,
  signOff,
  update,
  getProfile,
  changePassword,
  ping,
};

export default authApi;
