import { AC_SET_LOCATION } from '../actions/action-types';

const initialState = {};

const routerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case AC_SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    default:
      return state;
  }
};

export default routerReducer;
