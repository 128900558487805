import { get } from 'lodash';
import { createSelector } from 'reselect';

import { MODALS_ID, MODELS } from '../../constants';

export const getModalsList = state => get(state, MODELS.MODALS_LIST, []);

export const getModalId = (_, props) => get(props, 'id');

export const getModalIsOpen = createSelector(
  [getModalsList, getModalId],
  (modals, modalId) => {
    const i = modals.findIndex(({ id }) => id === modalId);

    return i === 0;
  },
);

export const getIsModalMode = state => getModalsList(state).length > 0;

export const createGetModal = modalId => state =>
  getModalsList(state).find(({ id }) => id === modalId);

export const getAuthModal = createGetModal(MODALS_ID.AUTH_MODAL);

export const getIsUpdateMode = createSelector([getAuthModal], authModal =>
  get(authModal, 'isUpdateMode', false),
);

export const createModalsListItemSelector = (modalId, defaultValue = null) =>
  createSelector(
    [getModalsList],
    modalsList => modalsList.find(({ id }) => modalId === id) || defaultValue,
  );
