import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from '../../../../Form';
import FormFieldset from '../../../../Form/shared/FormFieldset';
import Legend from '../../../../Form/shared/Legend';
import FormInput from '../../../../Form/shared/controls/FormInput';
import FormToolbar from '../../../../Form/shared/FormToolbar';
import FormButton from '../../../../Form/shared/FormButton';

import styles from './ChangePasswordForm.module.scss';

const initialState = {
  password: '',
  newPassword: '',
  confirmPassword: '',
  validation: {},
};

const ChangePasswordForm = ({ close, closeAll, onSubmit }) => {
  const [state, setState] = useState({ ...initialState });

  const FIELDS = [
    {
      id: 'password',
      label: 'Current password',
      type: 'password',
    },
    {
      id: 'newPassword',
      label: 'New password',
      type: 'password',
    },
    {
      id: 'confirmPassword',
      label: 'Confirm password',
      type: 'password',
    },
  ];

  const handleChange = e => {
    const { id, value } = e.target;
    setState({ ...state, [id]: value });
  };

  const getErrorMessage = id => state.validation[id] || '';

  /* const isValid = dirtyState => {
    return !FIELDS.some(({ id }) => isFilledString(dirtyState.validation[id]));
  }; */

  const submit = () => {
    const { validation: _, ...payload } = state;

    const callback = validation => {
      if (validation) {
        setState({ ...state, validation });
      } else {
        closeAll();
      }
    };

    onSubmit(payload, callback);
  };

  const controls = FIELDS.map(({ id, label, ...rest }) => (
    <FormInput
      key={id}
      id={id}
      label={label}
      name={id}
      value={state[id]}
      errorMessage={getErrorMessage(id)}
      onChange={handleChange}
      {...rest}
    />
  ));

  return (
    <Form
      className={styles['change-password-form']}
      name="changePassword"
      withCross
      onCrossClick={close}
    >
      <FormFieldset>
        <Legend>Change password</Legend>
        {controls}
      </FormFieldset>
      <FormToolbar>
        <FormButton label="Cancel" onClick={close} />
        <FormButton label="Ok" onClick={submit} />
      </FormToolbar>
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  close: PropTypes.func,
  closeAll: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

ChangePasswordForm.defaultProps = {
  close: () => {},
  closeAll: () => {},
};

export default ChangePasswordForm;
