const EVENTS = {
  RESIZE: 'RESIZE',
  KEY_LEFT: 'KEY_LEFT',
  KEY_RIGHT: 'KEY_RIGHT',
  KEY_UP: 'KEY_UP',
  KEY_DOWN: 'KEY_DOWN',
  ESCAPE: 'ESCAPE',
  REDO: 'REDO',
  UNDO: 'UNDO',
};

export default EVENTS;
