const REM = parseInt(
  getComputedStyle(document.documentElement)['font-size'],
  10,
);

const COMMON = {
  REM,
};

export default COMMON;
