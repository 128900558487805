const parseUrlAuthData = urlState => {
  try {
    const decoded = atob(urlState);
    const { l, st } = JSON.parse(decoded);

    return {
      ...(l && { login: l }),
      ...(l && st && { sessionToken: st }),
    };
  } catch (e) {
    return {};
  }
};

export default parseUrlAuthData;
