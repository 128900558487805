import React from 'react';
import PropTypes from 'prop-types';

import Form from '../../../../Form';
import FormFieldset from '../../../../Form/shared/FormFieldset';
import Legend from '../../../../Form/shared/Legend';
import Menu from '../../../../Menu';

import styles from './AccountMenuForm.module.scss';

const AccountMenuForm = ({
  isLogged,
  closeAllModals,
  onLogin,
  onLogout,
  openProfile,
}) => {
  const options = [
    {
      id: 'login',
      label: 'Login',
      isVisible: !isLogged,
      onClick: onLogin,
    },
    {
      id: 'logout',
      label: 'Logout',
      isVisible: isLogged,
      onClick: () => {
        onLogout(closeAllModals);
      },
    },
    {
      id: 'profile',
      label: 'User profile',
      isVisible: isLogged,
      onClick: openProfile,
    },
  ];

  return (
    <Form
      className={styles['account-menu-form']}
      name="accountMenu"
      withCross
      onCrossClick={closeAllModals}
    >
      <FormFieldset>
        <Legend>Account menu</Legend>
        <Menu options={options} />
      </FormFieldset>
    </Form>
  );
};

AccountMenuForm.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  closeAllModals: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  openProfile: PropTypes.func.isRequired,
};

export default AccountMenuForm;
